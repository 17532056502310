import React from 'react'

import css from './PageLoader.module.scss'

const PageLoader = () => (
  <div className={css.LoaderBkg}>
    <span className={css.Loader}></span>
  </div>
)

export default PageLoader

import React, { createContext, useState } from 'react'

export const AppContext = createContext(null)

const AppProvider = ({ children }) => {
  const [experience, setExperience] = useState(false)

  const showExperience = () => {
    console.log('showExperience')
    setExperience(true)
  }

  const closeModal = () => {
    setExperience(false)
  }

  return (
    <AppContext.Provider value={{ experience, closeModal, showExperience }}>
      {children}
    </AppContext.Provider>
  )
}

export default AppProvider
